<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { project_status } from "@/config/api/project-status";
import ProjectStatusModal from "@/components/project-status/project-status-modal";

export default {
  components: {
    Layout,
    PageHeader,
    ProjectStatusModal,
  },
  data() {
    return {
      title: "Project Status",
      items: [
        {
          text: "All",
        },
        {
          text: "Project Status",
          active: true,
        },
      ],
      projectList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "_id",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true, label: "Name" },
        { key: "status", sortable: true, label: "Status" },
        { key: "actions", sortable: true, label: "Actions" },
      ],
      selectedProject: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    // Set the initial number of items
    this.loadData();
  },
  methods: {
    editStatus(data) {
      this.selectedProject = data.item;
      this.$bvModal.show("modal-sheet");
    },
    hideActionModal() {
      this.$bvModal.hide("modal-sheet");
    },
    addStatus() {
      this.$bvModal.show("modal-sheet");
    },
    clearSelectedProject() {
      this.selectedProject = null;
    },
    /**
     * Search the table data with search input
     */
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    updatePage(page) {
      this.loadData(page);
    },
    loadData() {
      this.projectList = [];
      const api = project_status.getAll;
      this.generateAPI(api)
        .then((res) => {
          this.projectList = res.data.models;
          this.totalRows = res.data.models.length;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button id="addProperty" @click="addStatus" pill variant="primary"
              >Add Project Status</b-button
            >

            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Project Status</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="projectList"
                    :fields="fields"
                    responsive="sm"
                    :busy="$store.state.api.loading"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#11657f"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(name)="row">
                      <div
                        class="badge font-size-12 status"
                        :style="{
                          backgroundColor: row.item.color,
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div
                        class="badge font-size-12"
                        :style="{
                          fontWeight: row.value == 'active' ? 'bold' : '100',
                        }"
                      >
                        {{ row.value.toUpperCase() }}
                      </div>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <button class="action-btn" @click="editStatus(row)">
                        <i class="ri-edit-line"></i>{{ row.value }}
                      </button>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          @change="updatePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <ProjectStatusModal
      :selectedProject="selectedProject"
      v-on:clearSelectedProject="clearSelectedProject"
      v-on:reloadData="loadData"
      v-on:closeModal="hideActionModal"
    ></ProjectStatusModal>
  </Layout>
</template>

<style scoped>
#addProperty {
  float: right;
  margin-top: 10px;
}
.status {
  color: white;
  /* width: 5rem; */
  border: 1px solid black;
}
</style>
