export const project_status = {
    getAll: {
        url: 'projectstatus',
        method: 'GET',
        id: null,
        params: null
    },
    createStatus: {
        url: 'projectstatus',
        method: 'POST',
        id: null,
        params: null
    },
    editStatus: {
        url: 'projectstatus/',
        method: 'PUT',
        id: null,
        params: null
    }
}